import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

function Verify() {
    const [error, setError] = React.useState("");
    const { token } = useParams()

    const navigate = useNavigate();

    React.useEffect(() => {
        fetch('/api/invite/verify', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "token": token || ""
            }
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    navigate("/");
                } else {
                    setError("Failed to verify");
                }
            });
    }, [token, navigate])

    return (
        <>
            {error && <p>{error}</p>}
        </>
    )
}

export default Verify;
