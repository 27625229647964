import React from 'react';
import { localStoreContext } from '../components/LocalStore'
import { useNavigate } from "react-router-dom";

function SettingsPage() {
    const localStore = React.useContext(localStoreContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!localStore.store.authenticated) {
            navigate("/");
        };
    }, [localStore.store.authenticated, navigate]);

    return (
        <>
            <h1>Settings</h1>
        </>
    )
}
export default SettingsPage;
