import React from 'react';
import { authContext } from '../components/Auth';
import { localStoreContext } from '../components/LocalStore'
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form"

import { Button } from "../components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormDescription,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

const schema = z.object({
    email: z.string().email("Email must be a valid email"),
    password: z.string().min(8, "Password must be at least 8 characters").max(50, "Password must be less than 50 characters"),
})

function Register() {
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const [error, setError] = React.useState("");

    const auth = React.useContext(authContext);
    const localStore = React.useContext(localStoreContext);

    const navigate = useNavigate();
    React.useEffect(() => {
        if (localStore.store.authenticated) {
            navigate("/");
        }
    }, [localStore.store.authenticated, navigate])

    const onSubmit = (data: any) => {
        fetch('/api/register', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            },
            "body": JSON.stringify({ email: data.email, password: data.password })
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    auth.login(data.email, data.password).then((loginResult) => {
                        setError(loginResult.error);
                        if (loginResult.error) {
                            return;
                        }

                        const action = {
                            authenticated: loginResult.authenticated,
                            name: loginResult.email
                        };

                        localStore.insert(action);
                        localStore.updateLocalStorage();

                        navigate("/");

                    })
                } else {
                    setError("Failed to register");
                }
            });
    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 ml-auto mr-auto mt-10 w-80">
                    <b>Register</b>
                    <FormDescription>Create an account to get started</FormDescription>
                    <div className="space-y-8">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input type="password" placeholder="******" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type="submit">Submit</Button>
                </form>
            </Form>
            {error && <p>{error}</p>}
        </>
    )
}

export default Register;
