import React from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../components/Auth';
import { localStoreContext } from '../components/LocalStore'
import { useNavigate, useLocation } from "react-router-dom";

function AuthBar() {
    const navigate = useNavigate();
    const auth = React.useContext(authContext);
    const localStore = React.useContext(localStoreContext);

    const detailMessage = localStore.store.authenticated ? `Signed in as ${localStore.store.name}` : 'Not signed in';

    const location = useLocation();

    const ignore = ["register", "login", "passwordreset"]
    const locationSplit = location.pathname.split("/")

    let displayAuthBar = !ignore.includes(location.pathname)
    if (locationSplit.length > 1) {
        if (ignore.includes(locationSplit[1])) {
            displayAuthBar = false
        }
    }

    const handleLogout = () => {
        auth.logout();
        localStore.insert({
            authenticated: false,
            name: ""
        });
        if (!localStore.store.authenticated) {
            navigate("/");
        }
    }

    return <>
        {displayAuthBar &&
            <div className="bg-gray-100 px-8 py-6 mt-6 rounded-lg shadow-xl">
                <div className="px-2 py-2">{detailMessage}</div>
                {localStore.store.authenticated ?
                    <button
                        className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        type="submit"
                        onClick={handleLogout}>Logout</button>
                    :
                    <>
                        <Link to="/login"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            Login </Link>
                        <Link to="/register"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            Register </Link>
                    </>
                }
            </div>
        }
    </>

}

export default AuthBar;
