import * as React from 'react'
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form"

import { Button } from "../components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormDescription,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

const schema = z.object({
    password: z.string().min(8, "Password must be at least 8 characters").max(50, "Password must be less than 50 characters"),
})

function PasswordResetRedirect() {
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            password: "",
        },
    });

    const { token } = useParams()
    const [error, setError] = React.useState("");

    const [isValidToken, setIsValidToken] = React.useState(false);
    const [isLoadingVerify, setIsLoadingVerify] = React.useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);

    React.useEffect(() => {
        if (token) {
            console.log("token", token);
            fetch('/api/passwordreset/verify', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({ token })
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        setIsValidToken(true);
                    } else {
                    }
                });
            setIsLoadingVerify(false);
        }
    }, [token])

    const onSubmit = (data: any) => {
        setError("")

        fetch('/api/passwordreset/update', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            },
            "body": JSON.stringify({ token, password: data.password })
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    setIsLoadingSubmit(true)
                } else {
                    setIsLoadingSubmit(false)
                    setError("Failed to reset password")
                }
            });
    }

    return (
        <>
            {
                (!isLoadingVerify && isValidToken && !isLoadingSubmit) ?
                    <>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 ml-auto mr-auto mt-10 w-80">
                                <b>Password Reset</b>
                                <FormDescription></FormDescription>
                                <div className="space-y-8">
                                    <FormField
                                        control={form.control}
                                        name="password"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>New Password</FormLabel>
                                                <FormControl>
                                                    <Input type="password" placeholder="******" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <Button type="submit">Change Password</Button>
                            </form>
                        </Form>
                        {error && <p>{error}</p>}
                    </>
                    :
                    <>
                        <div className="ml-0 text-red-500">Invalid Token</div>
                    </>
            }
            {error && <p>{error}</p>}
        </>
    )
}

export default PasswordResetRedirect;

