import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarTrigger,
} from "./ui/menubar"
import { useNavigate } from "react-router-dom";
import { localStoreContext } from '../components/LocalStore'
import React from 'react'


function TopBar() {
    const localStore = React.useContext(localStoreContext);
    const navigate = useNavigate();

    return <>
        {localStore.store.authenticated &&
            <>
                <Menubar>
                    <MenubarMenu>
                        <MenubarTrigger onClick={() => navigate("/")}>Home</MenubarTrigger>
                    </MenubarMenu>
                    <MenubarMenu>
                        <MenubarTrigger>User</MenubarTrigger>
                        <MenubarContent>
                            <MenubarItem onClick={() => navigate("/settings")}>Settings</MenubarItem>
                        </MenubarContent>
                    </MenubarMenu>
                </Menubar>
            </>
        }
    </>


}

export default TopBar;
