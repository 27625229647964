// import React from 'react'
// import Users from './Users'
import AuthBar from './AuthBar'
import TopBar from './TopBar'
// import { localStoreContext } from '../components/LocalStore'

function Common({ children }: any) {
    return (
        <div className="App">
            <TopBar></TopBar>
            {children}
            <AuthBar></AuthBar>
        </div>
    )
}

export default Common;
