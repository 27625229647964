import './App.css';
import Common from './components/Common'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Settings from './pages/Settings'
import Register from './pages/Register'
import Pricing from './pages/Pricing'
import Verify from './pages/Verify'
import Home from './pages/Home'

import PasswordResetRequest from './pages/PasswordResetRequest'
import PasswordResetRedirect from './pages/PasswordResetRedirect'

function App() {
    return (
        <BrowserRouter>
            <Common>
                <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/register" element={<Register />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/settings" element={<Settings />} />

                    <Route path="/verify/:token" element={<Verify />} />

                    <Route path="/passwordreset/:token" element={<PasswordResetRedirect />} />
                    <Route path="/passwordreset" element={<PasswordResetRequest />} />

                </Routes>
            </Common>
        </BrowserRouter>
    );
}

export default App;
