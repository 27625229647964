import * as React from 'react';


interface authStateResult {
    authenticated: boolean;
    error: string;
    email: string;
}


const authContext = React.createContext({
    // Mutable ref object
    login: (email: string, password: string): Promise<authStateResult> => {
        return new Promise(() => {
            return {
                authenticated: false,
                error: "Not implemented",
                email: ""
            }
        })
    },
    register: (email: string, password: string) => { },
    logout: () => { },
});

const AuthProvider = ({ children }: any) => {
    async function register(email: string, password: string): Promise<authStateResult> {
        if (email && password) {
            await fetch('/api/register', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({ email, password })
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        return { "email": "email", "authenticated": true, "error": ""}
                    } else {
                        return { "email": "", "authenticated": false, "error": "Login failed" }
                    }
                });
        }
        return { "email": "", "authenticated": false, "error": "Email and password required" }
    }

    async function login(email: string, password: string): Promise<authStateResult> {
        if (email && password) {
            let result;
            await fetch('/api/login', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({ email, password })
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        result = { "email": email, "authenticated": true, "error": "" }
                    } else {
                        result = { "email": "", "authenticated": false, "error": 'Login Failed' }
                    }
                })
                .catch((e) => {
                    result = { "email": "", "authenticated": false, "error": e }
                });

            return result || { "email": "", "authenticated": false, "error": 'Login Failed' }

        } else {
            return { "email": "", "authenticated": false, "error": 'Email and password required' }
        }
    }

    async function logout(): Promise<boolean> {
        return fetch('/api/logout', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            }
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    return true
                } else {
                    return false
                }
            });
    }

    return (
        <authContext.Provider value={{ login, logout, register }}>
            {children}
        </authContext.Provider>
    )
}

export default AuthProvider;
export { authContext };
