import React from 'react';

import { useForm } from "react-hook-form"

import { Button } from "../components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormDescription,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

const schema = z.object({
    email: z.string().email("Email must be a valid email"),
})

function PasswordResetRequest() {
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    const [error, setError] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);


    const onSubmit = (data: any) => {
        fetch('/api/passwordreset/request', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            },
            "body": JSON.stringify({ email: data.email }),
        }).then(
            (resp) => {
                if (resp.status === 200) {
                } else {
                    setError("Failed to verify");
                }
            });
        setIsLoading(false);

    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 ml-auto mr-auto mt-10 w-80">
                    <b>Password Reset</b>
                    <FormDescription>Request a password reset</FormDescription>
                    <div className="space-y-8">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type="submit">Reset</Button>
                </form>
            </Form>

            {!isLoading && !error && <h1>Request Sent</h1>}
            {error && <p>{error}</p>}
        </>
    )
}

export default PasswordResetRequest;

