import React from 'react';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from '../components/CheckoutForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY || "");

function Pricing() {
    const [clientSecret, setClientSecret] = React.useState("");
    const options = {
        clientSecret: clientSecret,
    };

    React.useEffect(
        () => {
            fetch('/api/payments/createintent', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({})
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        resp.json().then(
                            (data) => {
                                setClientSecret(data.clientSecret);
                            }
                        );
                    } else {
                    }
                });
        },
        [setClientSecret]
    )

    return (
        <>
            {
                clientSecret && stripePromise &&
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm></CheckoutForm>
                </Elements >
            }
        </>
    )
}

export default Pricing;
