import Users from '../components/Users';

function Home() {
    return (
        <>
            <b>Home</b>
            <Users />
        </>
    )
}

export default Home;
